import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { styled } from '@mui/material/styles';
import { gql } from 'apollo-boost';
import {
    ListItemText,
    // List,
    MenuItem,
    Divider,
    Tooltip,
} from '@mui/material';
import { UserStore } from '../../../../context/user-store';
import {
    AlignItems,
    Breadcrumb,
    Column,
    DialogBox,
    Flex,
    SpaceBetween,
    Text,
    Menu,
    Button,
    ImgContainer,
    LinearProgress,
    BoldText,
    NotificationCloseCampaign,
    MenuButton,
    Toast,
} from '../../../../components';
import ReportModal from '../../../Report/ReportModal';

import dropdownArrow from '../../../../assets/images/icon-drop-down.svg';
import iconArchive from '../../../../assets/images/Atmos_Icons_Archive.svg';
import IconMaturity1 from '../icons/IconMaturity1';
import IconMaturity2 from '../icons/IconMaturity2';
import IconMaturity3 from '../icons/IconMaturity3';
import IconMaturity4 from '../icons/IconMaturity4';
import Router from '../../../../routes/router';


import { ASSESSMENT_FETCH_LIMIT, countProgress, isPointOneDecimalEnable } from '../../../../utils';
import { ASSESSMENT_LIST_PAGINATE, CLOSE_CAMPAIGN, CAMPAIGNS } from '../../query';
import { Assessment } from '../types';
import {
    CampaignGroupAssessment,
    CampaignAssessment,
    CampaignData,
} from '../MaturityOverview/types';
import {
    campaignMenuItems,
    campaignOverviewMenuWithTargetScore,
    assessmentsActionsHandlers,
    exportCSV,
    renderModal,
} from './MaturityOverviewHeaderHelpers';
import {
    isReadOnlyEnabled,
    isExportButtonEnabled,
} from '../../../../utils';
import { isAnyDescopedDomain } from '../../EvaluateHelpers';
import campaignEventChannel from '../../../../eventChannels';

const ARCHIVE_ASSESSMENTS = gql`
    mutation archiveAssessments3($ids: [ID!]!) {
        archiveAssessments(ids: $ids) {
            id
        }
    }
`;

type Props = {
    notFilteredData: any;
    selectedRow: string[];
    setIsLegendVisible: any;
    isLegendVisible: boolean;
    setCampaignAssessmentsData: any;
    campaignId: string;
    setTab: any;
    campaignData?: CampaignData;
    container: HTMLDivElement;
    campaignIsClosed: boolean;
    setSelectedRow: any;
    targetScoreEnabled: boolean;
    setShowTargetScore: any;
    showTargetScore: boolean;
    setShowCompensationControl: any;
    showCompensationControl: boolean;
    compensatingControlEnabled: boolean;
    assessmentsByCampaignWithGroupPageOffset: number;
};

const MaturityOverviewHeader: React.FC<Props> = ({
    notFilteredData,
    selectedRow,
    setIsLegendVisible,
    isLegendVisible,
    campaignId,
    setTab,
    campaignData,
    setCampaignAssessmentsData,
    container,
    campaignIsClosed,
    setSelectedRow,
    targetScoreEnabled,
    setShowTargetScore,
    showTargetScore,
    setShowCompensationControl,
    showCompensationControl,
    compensatingControlEnabled,
    assessmentsByCampaignWithGroupPageOffset
}) => {
    const [isCampaignMenuOpen, setIsCampaignMenuOpen] = useState<boolean>(false);
    const [isDownloadMenuOpen, setIsDownloadMenuOpen] = useState<boolean>(false);

    const [modalToggle, setModalToggle] = useState<boolean>(false);
    const [isExport, setIsexport] = useState<boolean>(false);
    const [isModalArchiveOpen, setIsModalArchiveOpen] = useState(false);
    const [isOpen, setOpen] = useState(false);
    const [closeCampaignSnackbar, setCloseCampaignSnackbar] = useState(false);
    const [isCheckboxEnable, setCheckBoxes] = useState([
        [false, false],
        [false, false],
    ]);
    const [domainDescoped, setDomainDescoped] = useState<boolean>(false);
    const [archiveAssessments] = useMutation(ARCHIVE_ASSESSMENTS, {
        refetchQueries: [
            {
                query: ASSESSMENT_LIST_PAGINATE,
                variables: {
                    campaignId,
                    id: campaignId,
                    pagination: {
                        skip: assessmentsByCampaignWithGroupPageOffset * ASSESSMENT_FETCH_LIMIT,
                        take: ASSESSMENT_FETCH_LIMIT
                    }
                },
            },
        ],
    });
    const selectedAssessmentType: string | unknown = 'all';

    const [isSelected, setIsSelected] = useState(0);
    const [viewPopoverAnchor, setViewPopoverAnchor] = React.useState<HTMLButtonElement | null>(null);


    const handleModalArchiveClose = () => {
        setIsModalArchiveOpen(false);
    };

    const handleModalArchiveOpen = () => {
        setIsModalArchiveOpen(true);
    };

    const handleModalArchiveSubmit = () => {
        archiveAssessments({
            variables: {
                ids: selectedRow,
            },
        })
            .then(() => {
                setSelectedRow([]);
                setIsModalArchiveOpen(false);
            })
            .catch((error) => {
                console.log('error', error);
            });
    };

    const handleSelectAssessmentType = useCallback(() => {
        let filteredList: Assessment[] = [];
        switch (selectedAssessmentType) {
            case 'progress':
                setCampaignAssessmentsData(notFilteredData);
                break;
            case 'archived':
                if (notFilteredData) {
                    filteredList = notFilteredData.map(
                        (assessmentGroup: CampaignGroupAssessment) => {
                            const filteredAssessments =
                                assessmentGroup.assessments.filter(
                                    (el: CampaignAssessment) => {
                                        return (
                                            el.campaignEntity.archivedDate !==
                                            null
                                        );
                                    }
                                );
                            if (filteredAssessments.length) {
                                return {
                                    ...assessmentGroup,
                                    assessments: filteredAssessments,
                                };
                            }
                            return { assessments: [], groupName: '' };
                        }
                    );
                    setCampaignAssessmentsData(filteredList);
                }
                break;
            default:
                setCampaignAssessmentsData(notFilteredData);
        }
    }, [notFilteredData, setCampaignAssessmentsData, selectedAssessmentType]);

    useEffect(() => {
        handleSelectAssessmentType();
        return () => {
            handleSelectAssessmentType();
        };
    }, [notFilteredData, selectedAssessmentType, handleSelectAssessmentType]);

    const {
        dispatch,
        state: { client = {}, user, globalSettings, currentTheme },
    } = useContext(UserStore);

    let campaign_closed_message = 'This campaign is closed - view only access.';

    const isReadOnly = (campaignData && campaignData.readOnly) || false;

    const readOnlyEnabled = isReadOnlyEnabled(globalSettings);

    const pointOneDecimalEnable = !isPointOneDecimalEnable(globalSettings)
        ? false
        : (campaignData && campaignData.pointOneDecimalEnable) || false;

    const isVisibleByReadOnly = !(isReadOnly && readOnlyEnabled);

    useEffect(() => {
        setCloseCampaignValue({...closeCampaignValue, closeDate: new Date(campaignData ? campaignData.updatedAt: 0)});
    }, [campaignData]);

    useEffect(() => {
        setDomainDescoped(isAnyDescopedDomain(notFilteredData));
    }, [notFilteredData]);

    if (!isVisibleByReadOnly && campaignIsClosed) {
        //BRUTE HACK TURN ON CAMPAIGN IS CLOSED MODE
        campaignIsClosed = true;
        campaign_closed_message =
            'This campaign is closed - You have read-only access to this campaign.';
    }

    if (readOnlyEnabled && !campaignIsClosed && isReadOnly) {
        //BRUTE HACK TURN ON CAMPAIGN IS CLOSED MODE
        campaignIsClosed = true;
        campaign_closed_message = 'You have read-only access to this campaign.';
    }

    const maturityScoreIcons = [
        <IconMaturity1
            selected={isSelected === 0}
            color="#de3328"
            backgroundColor="#f4dbd9"
            borderColor="#de3328"
            key={0}
        />,
    ];

    const campaignComplete =
        (campaignData &&
            campaignData.assessmentsCompletedCnt ===
                campaignData.assessmentsCnt) ||
        false;

    const isCampaignMember = campaignData?  campaignData.members.filter(cmp => cmp.id == user.id).length > 0 : false;
    const isCampaignReadOnly = campaignData && campaignData.readonlymembers ?  campaignData.readonlymembers?.filter(cmp => cmp.id == user.id).length > 0 : false;

    if (
        !(readOnlyEnabled && isReadOnly) ||
        (readOnlyEnabled && isReadOnly && campaignComplete)
    ) {
        maturityScoreIcons.push(
            <IconMaturity2
                selected={isSelected === 1}
                color="#de3328"
                backgroundColor="#f4dbd9"
                borderColor="#de3328"
                key={1}
            />,
            <IconMaturity3
                selected={isSelected === 2}
                color="#de3328"
                backgroundColor="#f4dbd9"
                borderColor="#de3328"
                key={2}
            />,
            <IconMaturity4
                selected={isSelected === 3}
                color="#de3328"
                backgroundColor="#f4dbd9"
                borderColor="#de3328"
                key={3}
            />
        );
    }

    const maturityTooltipTitle = [
        'Maturity overview',
        'Capability average',
        'Maturity box chart',
        'Domain average',
    ];

    const setCurrentTheme = (theme: string) => {
        dispatch({ type: 'SET_THEME', value: theme });
    };

    const maturityCrumbs = [
        {
            name: 'Evaluate (Campaigns)',
            path: '/main/evaluate/campaigns',
        },
        {
            name: `${client ? client.name : ''} | ${
                campaignData ? campaignData.title : ''
            }`,
        },
    ];

    const [closeCampaign] = useMutation(CLOSE_CAMPAIGN, {
        refetchQueries: [
            {
                query: CAMPAIGNS,
                variables: {
                    clientId: client.id,
                },
            },
        ],
    });

    const handleCloseCampaign = (data: {
        campaignId: string;
        assessmentType: number;
        submitForBenchmarking: string;
        pointOneDecimalEnable: boolean;
    }) => {
        const newAssessmentType = (type: number) => {
            switch (type) {
                case 1:
                    return 'PWC_FULL';
                case 2:
                    return 'PWC_LIGHT';
                case 3:
                    return 'SELF_ASSESSMENT';
                default:
                    return 'PWC_FULL';
            }
        };
        const submitForBenchmarking = data.submitForBenchmarking === 'yes';

        const pod = data.pointOneDecimalEnable;

        const newData = {
            ...data,
            submitForBenchmarking,
            assessmentType: newAssessmentType(data.assessmentType),
            pointOneDecimalEnable: pod,
            closedAt: closeCampaignValue.closeCurrentDate ? new Date() : closeCampaignValue.closeDate // or send null and calc on server side?
        };
        closeCampaign({
            variables: {
                data: newData,
            },
        }).then(() => {
            setOpen(false);
            setCloseCampaignValue({ radioValue: '', assessmentType: 0 , closeDate: new Date(0), closeCurrentDate: true});
            setSelectedRow([]);
            campaignEventChannel.emit('onCampaignClose', true);
        }).catch((e) => {
            console.error(e);
            alert(e);
        });
    };

    const hasAccessToDownload = (client.owner && user.id == client.owner?.id) || (campaignData && campaignData?.members.filter((cmp) => cmp.id == user.id).length>0);

    const [closeCampaignValue, setCloseCampaignValue] = useState({
        radioValue: '',
        assessmentType: 0,
        closeDate: new Date(0),
        closeCurrentDate: true
    });
    const [isSelectOpen, setSelectOpen] = useState(false);

    // todo delete this check after permanent solution
    const tempClientCheck=(client.name === 'Global Implementation' && client.id === 251);
    const isUserInManagerArray = user && campaignData && campaignData.manager && campaignData.manager.some((managerId: any) => managerId.id === user.id);

    return (
        <HeaderWrapper>
            {campaignIsClosed && (
                <NotificationCloseCampaign message={campaign_closed_message} />
            )}
            <SpaceBetween>
                <Breadcrumb crumbs={maturityCrumbs} />
                <SpaceBetween style={{ marginBottom: 20 }}>
                    <Column
                        style={{
                            paddingRight: 24,
                            borderRight: '1px solid #c5c5c5',
                        }}
                    >
                        <SpaceBetween style={{ marginBottom: 4 }}>
                            <BoldText style={{ fontSize: '14px' }}>
                                Campaign
                            </BoldText>
                            <Flex>
                                <BoldText
                                    style={{ marginRight: 5, fontSize: '14px' }}
                                >
                                    {campaignData ? campaignData.progress : 0}%
                                </BoldText>
                                <Text>Progress</Text>
                            </Flex>
                        </SpaceBetween>
                        <LinearProgress
                            progress={campaignData ? campaignData.progress : 0}
                            containerWidth="200px"
                        />
                    </Column>
                    <Column style={{ paddingLeft: 24 }}>
                        <SpaceBetween style={{ marginBottom: 4 }}>
                            <BoldText style={{ fontSize: '14px' }}>
                                Assessment
                            </BoldText>
                            <Flex>
                                <BoldText
                                    style={{ marginRight: 5, fontSize: '14px' }}
                                >
                                    {campaignData
                                        ? campaignData.assessmentsCompletedCnt
                                        : 0}
                                    /
                                    {campaignData
                                        ? campaignData.assessmentsCnt
                                        : 0}
                                </BoldText>
                                <Text>Completed</Text>
                            </Flex>
                        </SpaceBetween>
                        <LinearProgress
                            progress={
                                campaignData
                                    ? countProgress({
                                          progressLength:
                                              campaignData &&
                                              campaignData.assessmentsCnt,
                                          value:
                                              campaignData &&
                                              campaignData.assessmentsCompletedCnt,
                                      })
                                    : 0
                            }
                            containerWidth="200px"
                        />
                    </Column>
                </SpaceBetween>
            </SpaceBetween>
            <SpaceBetween style={{ width: '100%' }}>
                <DialogBox
                    title="Archive Assessment"
                    buttonSaveText="Archive campaign"
                    buttonCancelText="Cancel"
                    content="Are you sure you want to archive the current assessment?"
                    open={isModalArchiveOpen}
                    handleClose={handleModalArchiveClose}
                    handleSubmit={handleModalArchiveSubmit}
                />
            </SpaceBetween>
            <SpaceBetween>
                <AlignItems style={{ flexWrap: 'wrap' }}>
                    <AlignItems>
                        <Menu
                            top={'-2%'}
                            menuStyles={{ padding: 0 }}
                            setMenuStatus={setIsCampaignMenuOpen}
                            button={
                                <MenuButton
                                    text="Campaign"
                                    rightIcon={dropdownArrow}
                                    disabled={campaignIsClosed}
                                />
                            }
                            buttonDisabled={campaignIsClosed}
                            isMenuOpen={isCampaignMenuOpen}
                        >
                            {campaignMenuItems.map(
                                (
                                    el: {
                                        id: string;
                                        label: string;
                                    },
                                    i: number
                                ) =>
                                    el.id !== 'Divider' ? (
                                        <MenuItem
                                            key={el.label}
                                            disabled={(el.id === 'close' && tempClientCheck) || (!isUserInManagerArray && user.id === client.owner?.id && el.id ==='close') || (!isUserInManagerArray && user.id !== client.owner?.id)}
                                            onClick={() => {
                                                assessmentsActionsHandlers(
                                                    el.label,
                                                    setOpen,
                                                    el.id === 'edit'
                                                        ? 0
                                                        : el.id ===
                                                            'add'
                                                        ? 1
                                                        : undefined,
                                                    campaignData &&
                                                        campaignData.id
                                                );
                                                setIsCampaignMenuOpen(false);
                                                }
                                            }
                                        >
                                            <ListItemText
                                                primary={
                                                    <Text>
                                                        {el.label}
                                                    </Text>
                                                }
                                            />
                                        </MenuItem>
                                    ) : (
                                        <Divider key={0}/>
                                    )
                            )}
                        </Menu>
                        <Menu
                            top={'-2%'}
                            menuStyles={{ padding: 0, marginLeft: 16 }}
                            button={
                                <MenuButton
                                    text="Download"
                                    rightIcon={dropdownArrow}
                                    disabled={
                                        !hasAccessToDownload ||
                                        !isVisibleByReadOnly ||
                                        !isExportButtonEnabled(globalSettings)
                                    }
                                />
                            }
                            buttonDisabled={
                                !hasAccessToDownload ||
                                !isVisibleByReadOnly ||
                                !isExportButtonEnabled(globalSettings)
                            }
                            isMenuOpen={isDownloadMenuOpen}
                            setMenuStatus={setIsDownloadMenuOpen}
                        >
                            <MenuItem
                                onClick={() => {
                                    exportCSV(
                                        campaignData &&
                                            campaignData.id
                                    );
                                    setIsDownloadMenuOpen(false);
                                }}
                            >
                                <ListItemText
                                    primary={
                                        <Text>
                                            Download CSV
                                        </Text>
                                    }
                                />
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setTab(1);
                                    setIsSelected(0);
                                    setTimeout(() => {
                                        Router.goToMaturityOverview(campaignId);
                                    }, 1000);
                                    setModalToggle(true);
                                    setIsexport(true);
                                    setIsDownloadMenuOpen(false);
                                }}
                            >
                                <ListItemText
                                    primary={
                                        <Text>
                                            Download PPT
                                        </Text>
                                    }
                                />
                            </MenuItem>
                        </Menu>
                        <ReportModal
                            isExport={isExport}
                            modalToggle={modalToggle}
                            setModalToggle={setModalToggle}
                            campaignAssessmentsData={notFilteredData}
                            maturityCont={container}
                            pointOneDecimalEnable={pointOneDecimalEnable}
                        />
                        {campaignOverviewMenuWithTargetScore(
                            viewPopoverAnchor,
                            setViewPopoverAnchor,
                            showTargetScore,
                            setShowTargetScore,
                            showCompensationControl,
                            setShowCompensationControl,
                            targetScoreEnabled,
                            isLegendVisible,
                            setIsLegendVisible,
                            setCurrentTheme,
                            currentTheme,
                            compensatingControlEnabled
                        )}
                        {isCampaignMember && selectedRow.length !== 0 && (
                            <AlignItems>
                                <HeaderButton
                                    onClick={handleModalArchiveOpen}
                                    variant="outlined"
                                >
                                    <HeaderIcon
                                        src={iconArchive}
                                        alt="archive icon"
                                    />
                                    Archive
                                </HeaderButton>
                            </AlignItems>
                        )}
                    </AlignItems>
                </AlignItems>
                <AlignItems>
                    <Text style={{ marginRight: 12 }}>Maturity scores map</Text>
                    {maturityScoreIcons.map(
                        (icon: React.ReactNode, i: number) => (
                            i != 0 && !isCampaignMember && !isCampaignReadOnly && !isUserInManagerArray && !(client.owner?.id === user.id) ? <></> :
                            <Tooltip
                                title={maturityTooltipTitle[i]}
                                placement="top"
                                key={i}
                            >
                                <Button
                                    onClick={() => {
                                        setIsSelected(i);
                                        setTab(i + 1);
                                    }}
                                    style={{
                                        minWidth: 'unset',
                                        minHeight: 'unset',
                                        padding: 0,
                                        border: 'none'
                                    }}
                                >
                                    {icon}
                                </Button>
                            </Tooltip>
                        )
                    )}
                </AlignItems>
            </SpaceBetween>
            {renderModal({
                handleClose: () => setOpen(false),
                isOpen,
                closeCampaign: handleCloseCampaign,
                campaignId,
                campaignTitle: campaignData ? campaignData.title : '',
                closeCampaignValue,
                setCloseCampaignValue,
                isSelectOpen,
                setSelectOpen,
                allCapabilitiesCompleted: campaignData ? campaignData.progress === 100 && campaignData.status === 'ACTIVE' : false,
                benchmarkingEnabled: campaignData && campaignData.benchmarkingEnabled,
                domainDescoped,
                pointOneDecimalEnable,
                isCheckboxEnable,
                setCheckBoxes,
            })}
            <Toast
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={closeCampaignSnackbar}
                autoHideDuration={4000}
                icon="check"
                title="Campaign successfully closed"
                closeToast={setCloseCampaignSnackbar}
            />
        </HeaderWrapper>
    );
};
const HeaderWrapper = styled(Column)`
    width: 100%;
    background-color: #f8f8f8;
    margin-top: 24px;
`;

const HeaderButton = styled(Button)`
    && {
        margin-left: 20px;
    }
`;

const HeaderIcon = styled(ImgContainer)`
    padding: 6px;
`;

export default MaturityOverviewHeader;

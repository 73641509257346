import {
    ApolloClient,
    HttpLink,
    InMemoryCache,
    gql
  } from '@apollo/client';

import { resolvers, typeDefs } from './resolvers';
import { setContext } from '@apollo/client/link/context';
import { parseJwt } from '../../utils/UtilsHelpers';
//import { kcLogout, keycloak } from '../keycloak';
import {
    getLocalStorageItem,
} from '../../utils/local-storage';
import { deleteAuthCookies, EXTENDED_LOGOUT_TIME, getCookie, INITIAL_LOGOUT_TIME } from '../../utils';

export const cache = new InMemoryCache();

const httpLink =  new HttpLink({
    uri: window.__ENV__.REACT_APP_APP_URL + '/graphql'
});

function shouldContextBeCreated(operationName: string): boolean {
    // List of GQL request that should not require creating context
    const operationNames = ['addJwtToWhitelist', 'removeJwtFromWhitelist'];
    return operationNames.indexOf(operationName) < 0;
}
const authLink = setContext(async (operation, { headers }) => {
    if (
        operation.operationName &&
        !shouldContextBeCreated(operation.operationName)
    ) {
        return { ...headers };
    }
    const token = getCookie('pid-token');
    const clientLocal = localStorage.getItem('client');
    let clientUUID = null;
    if (clientLocal) {
        const clientParsed = JSON.parse(clientLocal);
        clientUUID = clientParsed.id;
    }
    return {
        headers: {
            'pearl-client-uuid': clientUUID,
            ...headers,
            authorization: token ? `Bearer ${token}` : ''
        }
    };
});

export const client = new ApolloClient({
    cache,
    link: authLink.concat(httpLink),
    typeDefs,
    resolvers,
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'cache-and-network'
        }
    }
});

function validateJwtNotExpired(){
    const token = getCookie('pid-token');
    if (token) {
        const jwt = parseJwt(token);
        const current_time = Date.now() / 1000;
        return jwt.exp >= current_time + INITIAL_LOGOUT_TIME;
    }
    return false;
}

export async function addJwtToWhitelist(jwt?: string, accessToken?: string): Promise<void> {
    const origin = 'pearl';
    if (jwt && accessToken) {
        await client.mutate({
            mutation: gql`
                mutation addJwtToWhitelist($jwt: String!, $origin: String!, $accessToken: String!) {
                    addJwtToWhitelist(jwt: $jwt, origin: $origin, accessToken: $accessToken) {
                        message
                    }
                }
            `,
            variables: { jwt, origin, accessToken }
        });
    }
}

export async function removeJwtFromWhitelist(): Promise<void> {
    const jwt = getCookie('pid-token');
    if (jwt&&validateJwtNotExpired()) {
        await client.mutate({
            mutation: gql`
                mutation removeJwtFromWhitelist($jwt: String!) {
                    removeJwtFromWhitelist(jwt: $jwt) {
                        message
                    }
                }
            `,
            variables: { jwt }
        }).finally(()=> {
            localStorage.clear();
            sessionStorage.clear();
            deleteAuthCookies();
            client.clearStore();
        });
    }
    else {
        localStorage.clear();
        sessionStorage.clear();
        deleteAuthCookies();
        client.clearStore();
    }
}


import React from 'react';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import ThreatScenariosComponent from './components/tabs/ThreatScenariosComponent/ThreatScenariosComponent';
import RiskDashboardComponent from './components/tabs/RiskDashboardComponent/RiskDashboardComponent';
import BoldText from '../../../components/base/Text/BoldText';
import Text from '../../../components/base/Text/Text';
import ApplyAndClearWrapper from '../../../components/base/Buttons/ApplyAndClearWrapper';
import ClearButton from '../../../components/base/Buttons/ClearButton';
import RedButton from '../../../components/base/Buttons/RedButton';
import {
    AlignItems,
    Column,
} from '../../../components/base/Layout';
import {
    AssessmentByCampaign,
    Risk,
    ObjectivesDropdownList,
    ObjectiveInList,
    Threat,
    CampaignsDataMenuProps,
} from './types';
import { gql } from 'apollo-boost';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { getCookie } from '../../../utils';

export const CAMPAIGNS = gql`
    query availableCampaignsByClient4(
        $clientId: Int!
        $supportsThreatAndRisk: Boolean
    ) {
        availableCampaignsByClient(
            clientId: $clientId
            supportsThreatAndRisk: $supportsThreatAndRisk
        ) {
            id
            name: title
            status
            readOnly
            pointOneDecimalEnable
            isCampaignEntityUser
            members {
                id
            }
        }
    }
`;

export const SAVE_RISKS = gql`
    mutation saveRisks(
        $clientId: Int!
        $campaignId: String!
        $risks: [SaveRiskInput!]!
    ) {
        saveRisks(clientId: $clientId, campaignId: $campaignId, risks: $risks) {
            id
        }
    }
`;

export const DELETE_RISKS = gql`
    mutation deleteRisks($ids: [ID!]!, $campaignId: String!) {
        deleteRisks(ids: $ids, campaignId: $campaignId) {
            message
        }
    }
`;

export const DELETE_THREATS = gql`
    mutation deleteThreats($ids: [ID!]!, $campaignId: String!) {
        deleteThreats(ids: $ids, campaignId: $campaignId) {
            message
        }
    }
`;

export const SAVE_THREATS = gql`
    mutation saveThreats($campaignId: String!, $threats: [SaveThreatInput!]!) {
        saveThreats(campaignId: $campaignId, threats: $threats) {
            id
        }
    }
`;

export const ASSESSMENT_LIST = gql`
    query assessmentsByCampaign2($campaignId: ID!) {
        assessmentsByCampaign(campaignId: $campaignId) {
            id
            campaign {
                id
                title
                status
            }
            campaignEntity {
                id
                name
                archivedDate
            }
            permission
            state {
                status
                inScope
                averageScore
                domains {
                    id
                    name
                    averageScore
                    moderatedAverageScore
                    capabilities {
                        status
                        id
                        name
                        averageScore
                        inScope
                        moderatedScore
                        objectives {
                            id
                            score
                            inScope
                            notes
                        }
                    }
                }
            }
        }
    }
`;

export const RISK_ATTRIBUTES = gql`
    query inherentRiskAttributesMap {
        inherentRiskAttributesMap {
            inherentImpactRating {
                VERY_LOW
                LOW
                MEDIUM
                HIGH
                VERY_HIGH
            }
            threatProximity {
                VERY_LOW
                LOW
                MEDIUM
                SEVERE
                CRITICAL
            }
            threatSophistication {
                VERY_LOW
                LOW
                MEDIUM
                SEVERE
                CRITICAL
            }
            threatPrevalence {
                VERY_LOW
                LOW
                MEDIUM
                SEVERE
                CRITICAL
            }
        }
    }
`;

export const RISKS_LIST = gql`
    query riskAndThreatsWithAssessments(
        $campaignId: String!
        $assessmentIds: [String!]
    ) {
        risks(campaignId: $campaignId, assessmentIds: $assessmentIds) {
            id
            title
            description
            inherentImpactRating
            threatProximity
            threatSophistication
            threatPrevalence
            threats {
                id
            }
            riskScore
            residualRate
        }
        threatsWithAssessments(
            campaignId: $campaignId
            assessmentIds: $assessmentIds
        ) {
            id
            title
            description
            assessments {
                id
                name
                capabilities {
                    id
                    name
                    status
                    inScope
                    objectives {
                        id
                        isKey
                        name
                        description
                        type
                        score
                        inScope
                    }
                }
                threatResilienceRating
            }
            averageThreatResilienceScore
            averageThreatResilienceRating
            pResilienceAverageScore
            dResilienceAverageScore
            cResilienceAverageScore
        }
    }
`;

export const THREATS_DASHBOARD = gql`
    query threatsWithAssessments(
        $campaignId: String!
        $assessmentIds: [String!]
    ) {
        threatsWithAssessments(
            campaignId: $campaignId
            assessmentIds: $assessmentIds
        ) {
            id
            title
            description
            assessments {
                id
                name
                capabilities {
                    id
                    name
                    status
                    inScope
                    objectives {
                        id
                        isKey
                        name
                        description
                        type
                        score
                        inScope
                    }
                }
                threatResilienceRating
                threatResilienceScore
                pResilienceScore
                dResilienceScore
                cResilienceScore
            }
            createdAt
            updatedAt
            averageThreatResilienceRating
            averageThreatResilienceScore
            pResilienceAverageScore
            dResilienceAverageScore
            cResilienceAverageScore
        }
    }
`;

export const THREATS_LIST = gql`
    query threats($campaignId: String!) {
        threats(campaignId: $campaignId) {
            id
            title
            description
            objectives {
                isKey
                reference
            }
            risks {
                id
            }
            updatedAt
            createdAt
        }
    }
`;

export const OBJECTIVES_FOR_CAMPAIGN = gql`
    query frameworkByCampaign($campaignId: ID!) {
        frameworkByCampaign(campaignId: $campaignId) {
            definition {
                domains {
                    capabilities {
                        name
                        objectives {
                            title
                            name
                            reference
                            threatAndRiskType
                        }
                    }
                }
                supportsThreatAndRisk
            }
        }
    }
`;

export const renderModal = ({
    handleDiscard,
    handleSave,
    isOpen,
    title,
    text,
}: {
    isOpen: boolean;
    handleDiscard: () => void;
    handleSave: () => void;
    title: string;
    text: string;
}) => {
    return (
        <div>
            <TARDialog
                open={isOpen}
                onClose={handleDiscard}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                // disableBackdropClick
            >
                <DialogTitle
                    style={{ width: '588px', paddingBottom: 0 }}
                    id="alert-dialog-title"
                >
                    <AlignItems style={{ justifyContent: 'space-between' }}>
                        <AlignItems style={{ marginTop: 8 }}>
                            <TARBoldText>{title}</TARBoldText>
                        </AlignItems>
                    </AlignItems>
                    <Text style={{ marginLeft: '9px' }}>{text}</Text>
                </DialogTitle>

                <DialogActions>
                    <ButtonCancel onClick={handleDiscard} variant="outlined">
                        Discard
                    </ButtonCancel>

                    <ButtonWithMarginLeft
                        onClick={handleSave}
                        variant="contained"
                    >
                        Save
                    </ButtonWithMarginLeft>
                </DialogActions>
            </TARDialog>
        </div>
    );
};

const ButtonWithMarginLeft = styled(RedButton)`
    && {
        text-transform: capitalize;
        margin-left: 20px;
        min-height: 48px;
        font-size: 18px;
    }
`;

const ButtonCancel = styled(Button)`
    && {
        text-transform: capitalize;
        border: none;
        font-size: 18px;
        color: #575757;
        padding-right: 24px;
    }
`;

const TARBoldText = styled(BoldText)`
    color: #40403e;
    font-size: 18px;
    margin-left: 8px;
`;

const TARDialog = styled(Dialog)`
    .MuiDialog-paperWidthSm {
        max-width: none;
        padding-top: 8px;
        padding-bottom: 9px;
    }
    .MuiDialogActions-root {
        padding: 16px 24px 16px 16px;
    }
`;

export const INITIAL_VALUES = {
    title: '',
    description: '',
    impactRating: '',
    threatProximity: '',
    threatSophistication: '',
    threatPrevalence: '',
    threatScenarios: [],
    inherentImpactRating: '',
};

export const INITIAL_THREAT_VALUES = {
    id: '',
    title: '',
    description: '',
    objectives: [],
};

export const INITIAL_RISK_VALUES: Risk = {
    title: '',
    description: '',
    inherentImpactRating: '',
    threatProximity: '',
    threatSophistication: '',
    threatPrevalence: '',
    threats: [],
};

export const ButtonWrapper = styled(ApplyAndClearWrapper)`
    padding-left: 8px;
`;

export const ClearAllButton = styled(ClearButton)`
    width: 50%;
`;

export const renderDeleteModal = ({
    handleClose,
    handleSend,
    isOpenDeleteConfirmation,
}: {
    isOpenDeleteConfirmation: {
        objectId?: string | number;
        riskIndex?: number;
        objectName: string;
        isOpen: boolean;
        modalType: string;
        canDelete?: boolean;
    };
    handleClose: () => void;
    handleSend: () => void;
}) => {
    return (
        <div>
            <RiskDialog
                open={isOpenDeleteConfirmation.isOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                // disableBackdropClick
            >
                <DialogTitle style={{ width: '451px' }} id="alert-dialog-title">
                    <Column style={{ marginTop: 8 }}>
                        {isOpenDeleteConfirmation.canDelete ? (
                            <RiskBoldText>
                                Delete {isOpenDeleteConfirmation.modalType}?
                            </RiskBoldText>
                        ) : (
                            <RiskBoldText>Delete threat scenario?</RiskBoldText>
                        )}

                        {isOpenDeleteConfirmation.canDelete ? (
                            <RiskText>
                                Are you sure you would like to delete{' '}
                                {isOpenDeleteConfirmation.objectName}{' '}
                                {isOpenDeleteConfirmation.modalType}? Deleting a{' '}
                                {isOpenDeleteConfirmation.modalType} will
                                permanently remove it from Connected Risk
                                Engine.
                            </RiskText>
                        ) : (
                            <RiskText>
                                This threat scenario has one or more associated
                                risks. Remove the risk(s) before deleting the
                                threat scenario.
                            </RiskText>
                        )}
                    </Column>
                </DialogTitle>

                <DialogActions>
                    <DeleteButtonCancel
                        onClick={handleClose}
                        variant="outlined"
                    >
                        Cancel
                    </DeleteButtonCancel>

                    {isOpenDeleteConfirmation.canDelete ? (
                        <ButtonWithMarginLeft
                            onClick={handleSend}
                            variant="contained"
                        >
                            {' '}
                            Confirm{' '}
                        </ButtonWithMarginLeft>
                    ) : (
                        ''
                    )}
                </DialogActions>
            </RiskDialog>
        </div>
    );
};

const DeleteButtonCancel = styled(ButtonCancel)`
    font-size: 16px;
    font-weight: bold;
    color: #525252;
`;

const RiskText = styled(Text)`
    font-size: 16px;
    color: #40403e;
    margin-left: 8px;
`;

const RiskBoldText = styled(BoldText)`
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0.5px;
    color: #575757;
    margin-left: 8px;
    margin-bottom: 16px;
`;

const RiskDialog = styled(Dialog)`
    .MuiDialog-paperWidthSm {
        max-width: none;
        padding-top: 8px;
        padding-bottom: 9px;
    }
    .MuiDialogActions-root {
        padding: 16px 24px 16px 16px;
    }
`;

export const renderContent = ({
    activeTab,
    setActiveTab,
    isAddButtonDisabled,
    threatAndRiskList,
    assessments,
    setAddTARModalOpen,
    isGuidance,
    isOnlyKeyVisible,
    setAddModal,
    setOpenRiskId,
    setOpenThreatId,
    setOpenDeleteConfirmation,
    threatDashboard,
    listOfAssessmentIds,
    selectedCampaign,
    pointOneDecimalEnable,
}: {
    activeTab: number;
    setActiveTab: any;
    isAddButtonDisabled: boolean;
    threatAndRiskList?: any[];
    assessments?: AssessmentByCampaign[];
    setAddTARModalOpen: any;
    isGuidance: boolean;
    isOnlyKeyVisible: boolean;
    setAddModal: any;
    setOpenRiskId: any;
    setOpenThreatId: any;
    setOpenDeleteConfirmation: any;
    threatDashboard?: Threat[];
    listOfAssessmentIds: string[];
    selectedCampaign: CampaignsDataMenuProps;
    pointOneDecimalEnable: boolean;
}) => {
    const contentList = {
        [`${1}`]: (
            <ThreatScenariosComponent
                isAddButtonDisabled={isAddButtonDisabled}
                setAddTARModalOpen={setAddTARModalOpen}
                assessments={assessments}
                threatDashboard={threatDashboard}
                isOnlyKeyVisible={isOnlyKeyVisible}
                isGuidance={isGuidance}
                setOpenDeleteConfirmation={setOpenDeleteConfirmation}
                threatList={threatAndRiskList}
                setOpenThreatId={setOpenThreatId}
                setAddModal={setAddModal}
                listOfAssessmentIds={listOfAssessmentIds}
            />
        ),
        [`${2}`]: (
            <RiskDashboardComponent
                isAddButtonDisabled={isAddButtonDisabled}
                setActiveTab={setActiveTab}
                riskList={threatAndRiskList}
                threatDashboard={threatDashboard}
                setAddTARModalOpen={setAddTARModalOpen}
                isGuidance={isGuidance}
                setOpenRiskId={setOpenRiskId}
                isOnlyKeyVisible={isOnlyKeyVisible}
                setAddModal={setAddModal}
                setOpenDeleteConfirmation={setOpenDeleteConfirmation}
                listOfAssessmentIds={listOfAssessmentIds}
                pointOneDecimalEnable={pointOneDecimalEnable}
            />
        ),
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return contentList[activeTab] || contentList[0];
};

export const findObjectiveType = (
    objectiveRef: string,
    allObjectives: ObjectivesDropdownList[] | undefined
) => {
    let type = '';
    if (allObjectives) {
        allObjectives.forEach((cap: ObjectivesDropdownList) => {
            const objective = cap.objectives.find(
                (obj: ObjectiveInList) => obj.reference === objectiveRef
            );
            if (objective) {
                type = objective.threatAndRiskType;
            }
        });
    }
    return type;
};

export const actionDropdownOptions = (buttonDisable: boolean) =>
    !buttonDisable
        ? [
              'View blue theme',
              'View RAG theme 1',
              'View RAG theme 2',
              'Divider',
              'Export CSV',
          ]
        : ['View blue theme', 'View RAG theme 1', 'View RAG theme 2'];

export const actionDropdownFunction = (
    name: string,
    setTheme: any,
    campaignID?: string
) => {
    switch (name) {
        case 'Export CSV':
            campaignID && exportCSV(campaignID);
            break;
        case 'View blue theme':
            setTheme('blue');
            break;
        case 'View RAG theme 1':
            setTheme('RAGtheme1');
            break;

        case 'View RAG theme 2':
            setTheme('RAGtheme2');
            break;
    }
};

const exportCSV = (id: string) => {
    const tkn = getCookie('pid-token');
    const clientLocal = localStorage.getItem('client');
    let clientUUID = null;
    if (clientLocal) {
        const clientParsed = JSON.parse(clientLocal);
        clientUUID = clientParsed.id;
    }
    axios
        .get(
            window.__ENV__.REACT_APP_APP_URL +
                '/threat_and_risk/' +
                id +
                '/csv',
            {
                params: {},
                headers: {
                    'pearl-client-uuid': clientUUID,
                    Authorization: `Bearer ${tkn}`,
                },
            }
        )
        .then((response) => {
            const blob = new Blob([response.data], {
                type: 'application/octet-stream',
            });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.setAttribute('href', url);
            link.setAttribute('download', 'report.csv');
            link.click();
            link.remove();
        })
        .catch((error) => {
            console.log(error);
        });
};


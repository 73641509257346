import React, {
    useContext,
    useState,
    useEffect,
    useCallback,
    useMemo
} from 'react';
import { styled } from '@mui/material/styles';
import Modal from 'react-modal';
import { useQuery, useMutation, gql } from '@apollo/react-hooks';
import CapabilityMenu from '../../../components/Menu/CapabilityMenu';
import { UserStore } from '../../../context/user-store';
import { useLocation } from 'react-router-dom';

import vectorIcon from '../../../assets/images/icon-maturity-overview.svg';
import vectorIconWhite from '../../../assets/images/icon-maturity-overview-white.svg';
import noteAdd from '../../../assets/images/icon-note-add.svg';
import noteAddWhite from '../../../assets/images/icon-note-add-white.svg';
import eventNoteIcon from '../../../assets/images/icon-event-note.svg';
import eventNoteIconWhite from '../../../assets/images/icon-event-note-white.svg';

import {
    AlignItems,
    ArrowTooltip,
    RedButton,
    Breadcrumb,
    Button,
    Column,
    ContentWrapper,
    DescopePopup,
    DialogBox,
    Flex,
    H1Text,
    ImgContainer,
    JustifyContentCenter,
    LinearProgress,
    Link,
    Notification,
    SpaceBetween,
    Tab,
    Text,
    VerticalLine,
    SpinnerWithOverlay,
    NotificationCloseCampaign
} from '../../../components';

import MeetingNotes from './MeetingNotes/MeetingNotes';
import Moderation from './Moderation/Moderation';
import Objectives from './Objectives/Objectives';
import WorkingNotesModal from './WorkingNotesModal/WorkingNotesModal';
import iconAssCovered from '../../../assets/images/icon_ass covered.svg';
import iconPen from '../../../assets/images/icon-pen.svg';
import requestAmendsIcon from '../../../assets/images/request_amends_icon.svg';
import approveIcon from '../../../assets/images/approveIcon.svg';
import sendIcon from '../../../assets/images/mdi_send.svg';

import {
    useCapability,
    useCapabilityObjectives,
    useAssessmentDomainsList,
    useDomainsList
} from './MaturityScoreHelpers';
import { autoSaveMessage, capabilityModeratedMessage, countProgress, isReadOnlyEnabled, isPointOneDecimalEnable, isCompensatingControlEnable, NotificationTypeEnum, NotificationCategoryEnum, PermissionTypeEnum } from '../../../utils';
import { customModalStyles } from '../../../components/PopUps/constants';

import Router from '../../../routes/router';

import {
    CampaignData,
    MaturityTabsList,
    MaturityMatch,
    CurrentCapability,
    FormData,
    AssessmentData,
    AssessmentDomain,
    AssessmentCapability,
    CurrentIdsData,
    CapabilityObjectiveWithDescriptions,
    MaturityScoreData,
    FormValues,
    ResponseStatus
} from './types';
import { DescopeIcon } from '../Common';

import ReactQuill, { ReactQuillProps } from 'react-quill';
import Toast from '../../../components/Toast';
import { isNISTFramework } from './Objectives/ObjectivesHelpers';
import { APPROVE_ASSESSMENT_CAPABILITY, ASSESSMENT_BY_ID, ASSESSMENT_CAPABILITY_WITH_FRAMEWORK, CAMPAIGN_FRAMEWORK_SETTINGS, CREATE_NOTIFICATION, REQUEST_ASSESSMENT_CAPABILITY_AMENDS, REQUEST_ASSESSMENT_CAPABILITY_REVIEW, UPDATE_ASSESSMENT_CAPABILITY_SCORE, UPDATE_WORKING_NOTES } from './MaturityScoreQueries';


const BLOCK_TYPE_DROPDOWN = [
    { label: 'Normal', style: 'unstyled' },
    { label: 'Heading Large', style: 'header-one' },
    { label: 'Heading Medium', style: 'header-two' },
    { label: 'Heading Small', style: 'header-three' }
];

const INLINE_STYLE_BUTTONS = [
    { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' },
    { label: 'Monospace', style: 'CODE' }
];

const BLOCK_TYPE_BUTTONS = [
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' }
];


type GroupName =
    | 'INLINE_STYLE_BUTTONS'
    | 'BLOCK_TYPE_BUTTONS'
    | 'LINK_BUTTONS'
    | 'BLOCK_TYPE_DROPDOWN'
    | 'HISTORY_BUTTONS'
    | 'IMAGE_BUTTON';

type StyleConfig = {
    label: string;
    style: string;
    className?: string;
};
type StyleConfigList = StyleConfig[];

type ToolbarConfig = {
    display: GroupName[];
    extraProps?: object;
    INLINE_STYLE_BUTTONS: StyleConfigList;
    BLOCK_TYPE_DROPDOWN: StyleConfigList;
    BLOCK_TYPE_BUTTONS: StyleConfigList;
};

const updateNotification = gql`
    mutation updateNotificationByCapabilityId(
        $managerId: [Int!]!
        $domainId: String!
        $capabilityId: String!
        $assessmentId: String!
    ) {
        updateNotificationByCapabilityId(
            managerId: $managerId
            domainId: $domainId
            capabilityId: $capabilityId
            assessmentId: $assessmentId
        )
    }
`;

const renderContent = ({
    frameworkCapabilities,
    activeTab,
    setLoadingStatus,
    setSavingStatus,
    currentDomainIndex,
    currentCapabilityIndex,
    entityScores,
    currentAssessmentId,
    currentCapability,
    setActiveTab,
    currentCapabilityId,
    currentDomainId,
    currentIdsData,
    capabilityObjectives,
    campaignData,
    canEdit,
    match,
    isModalOpen,
    readOnlyEnabled,
    isReadOnly,
    setDirty,
    isDirty,
    pointOneDecimalEnable,
    setResponseStatusData,
    isObjectiveComponentExist,
    frameworkName,
    compensatingControlEnabled,
    assessmentData
}: {
    frameworkCapabilities: any[];
    activeTab: number;
    currentDomainIndex: number;
    currentCapabilityIndex: number;
    setSavingStatus: (status: string) => void;
    entityScores: string[];
    currentAssessmentId: string;
    currentCapability: CurrentCapability;
    setActiveTab: (activeTab: number) => void;
    setLoadingStatus: (status: boolean) => void;
    currentCapabilityId: string;
    currentDomainId: string;
    currentIdsData: CurrentIdsData;
    capabilityObjectives: CapabilityObjectiveWithDescriptions[];
    campaignData?: CampaignData;
    canEdit: boolean;
    match: MaturityMatch;
    isModalOpen: boolean;
    readOnlyEnabled: boolean;
    isReadOnly: boolean;
    setDirty: any;
    isDirty: boolean;
    pointOneDecimalEnable: boolean;
    setResponseStatusData: any;
    isObjectiveComponentExist: boolean;
    frameworkName: string;
    compensatingControlEnabled: boolean;
    assessmentData?: AssessmentData;
}) => {
    const contentList = {
        [`${1}`]: (
            <Objectives
                setLoadingStatus={setLoadingStatus}
                scoreMarks={entityScores}
                currentCapability={currentCapability}
                currentDomainIndex={currentDomainIndex}
                currentCapabilityIndex={currentCapabilityIndex}
                currentAssessmentId={currentAssessmentId}
                currentCapabilityId={currentCapabilityId}
                currentDomainId={currentDomainId}
                capabilityObjectives={capabilityObjectives}
                canEdit={canEdit}
                currentIdsData={currentIdsData}
                isDescopeModalOpen={isModalOpen}
                readOnlyEnabled={readOnlyEnabled}
                isReadOnly={isReadOnly}
                setDirty={setDirty}
                isDirty={isDirty}
                match={match}
                pointOneDecimalEnable={pointOneDecimalEnable}
                isObjectiveComponentExist={isObjectiveComponentExist}
                campaignData={campaignData}
                frameworkName={frameworkName}
                compensatingControlEnabled={compensatingControlEnabled}
                assessmentReadOnly = {assessmentData?.assessment.permission === PermissionTypeEnum.READ_ONLY_ACCESS}
            />
        ),
        [`${2}`]: (
            <Moderation
                frameworkCapabilities={frameworkCapabilities}
                currentIdsData={currentIdsData}
                currentCapability={currentCapability}
                currentAssessmentId={currentAssessmentId}
                scoreMarks={entityScores}
                campaignData={campaignData}
                setSavingStatus={setSavingStatus}
                canEdit={canEdit}
                match={match}
                readOnlyEnabled={readOnlyEnabled}
                isReadOnly={isReadOnly}
                setResponseStatusData={setResponseStatusData}
                isObjectiveComponentExist={isObjectiveComponentExist}
                pointOneDecimalEnable={pointOneDecimalEnable}
            />
        ),
        [`${3}`]: (
            <MeetingNotes
                assessmentId={currentAssessmentId}
                currentCapabilityId={currentCapability.id}
                canEdit={canEdit}
                readOnlyEnabled={readOnlyEnabled}
                isReadOnly={isReadOnly}
            />
        )
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return contentList[activeTab] || contentList[0];
};

const MaturityScore: React.FC<{
    match: MaturityMatch;
    canEdit: boolean;
    setCurrentIdsData: any;
    currentIdsData: { capabilityId: string; domainId: string };
    campaignData?: CampaignData;
    campaignDataLoading: boolean;
    campaignIsClosed: boolean;
}> = ({
    match,
    canEdit,
    setCurrentIdsData,
    currentIdsData,
    campaignData,
    campaignDataLoading,
    campaignIsClosed
}) => {
    interface stateType {
        slug?: string;

    }

    const { state } = useLocation();
    const slug = state && state.slug ? state.slug : 'MaturityOverview';

    const [savingStatus, setSavingStatus] = useState<string>(
        'All changes saved in Connected Risk Engine'
    );
    const [isDirty,setDirty]=useState<boolean>(false);
    const { loading: assessmentLoading, data: assessmentData } = useQuery<
        AssessmentData
    >(ASSESSMENT_BY_ID, {
        variables: {
            id: match.params.assessmentId
        }
    });

    const {
        state: { user, client = {}, globalSettings }
    } = useContext(UserStore);

    const {
        loading,
        data = {
            frameworkByCampaign: {
                id: null,
                definition: {
                    name: '',
                    guidance: '',
                    scores: [],
                    domains: [],
                    hasObjectiveComponents:false
                }
            },
            assessmentCapability: {
                id: '',
                name: '',
                status: '',
                objectives: [],
                averageScore: null,
                feedback: [],
                inScope: false,
                meetings: [],
                moderatedScore: 0,
                workingNotes: {
                    text: ''
                },
                objectivesScoredCnt: 0
            }
        }
    } = useQuery<MaturityScoreData>(ASSESSMENT_CAPABILITY_WITH_FRAMEWORK, {
        variables: {
            campaignId: match.params.campaignId,
            data: {
                assessmentId: match.params.assessmentId,
                domainId: currentIdsData.domainId,
                capabilityId: currentIdsData.capabilityId
            }
        }
    });

    const { data: frameworkSettings } = useQuery(
        CAMPAIGN_FRAMEWORK_SETTINGS,
        {
            variables: {
                campaignId: match.params.campaignId
            }
        },
    );

    const [requestAssessmentCapabilityReview] = useMutation(
        REQUEST_ASSESSMENT_CAPABILITY_REVIEW
    );

    const [createNotification] = useMutation(CREATE_NOTIFICATION);

    const [isDialogBoxSubmitOpen, setIsDialogBoxSubmitOpen] = useState<boolean>(
        false
    );
    const [isDialogBoxErrorOpen, setIsDialogBoxErrorOpen] = useState<boolean>(
        false
    );
    const [isChangeTabPopUpOpen, setIsChangeTabPopUpOpen] = useState<boolean>(false);
    const [compensatingControlEnabled, setCompensatingControlEnabled] = useState<boolean>(false);

    const [confirmedTabId,setConfirmedTabId]=useState<number>(0);
    const managerIds = campaignData?.campaign.manager.map(user => user.id);

    const handleRequestAssessmentCapabilityReview = () => {
        requestAssessmentCapabilityReview({
            variables: {
                data: {
                    assessmentId: match.params.assessmentId,
                    domainId: currentIdsData.domainId,
                    capabilityId: currentIdsData.capabilityId
                }
            }
        })
            .then(responseData => {
                const {
                    data: {
                        requestAssessmentCapabilityReview: { name, status }
                    }
                } = responseData;
                setResponseStatusData({ name, status });
                setIsDialogBoxSubmitOpen(true);

                const domainName =  assessmentData?.assessment.state?.domains?.find((domain_id: any) => domain_id.id === currentIdsData.domainId);
                const capabilityName = domainName?.capabilities.find((capability_id: any) => capability_id.id === currentIdsData.capabilityId);
                managerIds?.forEach((managerId: any) => {
                    createNotification(
                        {
                            variables:
                                {
                                    input: {
                                        userId: managerId,
                                        notificationType: NotificationTypeEnum.INFO,
                                        notificationCategory: NotificationCategoryEnum.ASSESSMENT,
                                        notificationMessage:
                                            `Assessment: ${assessmentData?.assessment?.campaignEntity.name.toUpperCase()}, Domain: ${domainName?.name.toUpperCase()}, Capability: ${capabilityName?.name.toUpperCase()} needs your Approval`,
                                        referenceID: JSON.stringify({
                                            campaignId: campaignData?.campaign.id,
                                            assessmentId: match.params.assessmentId,
                                            domainId: currentIdsData.domainId,
                                            capabilityId: currentIdsData.capabilityId,
                                            companyId: client.id,

                                        })
                                        ,
                                        wasRead:
                                            false,
                                    },
                                },
                        }
                    );
                });





            })
            .catch(err => {
                setIsDialogBoxErrorOpen(true);
                console.log('err', err);
            });
    };

    const [campaignTitle, setCampaignTitle] = useState<string>('');
    const [entityTitle, setEntityTitle] = useState<string>('');
    const [entityScores, setEntityScores] = useState<string[]>([
        '',
        '',
        '',
        '',
        '',
        ''
    ]);
    const [isObjectiveComponentExist,setIsObjectiveComponentExist]=useState<boolean>(false);

    useEffect(() => {
        if (
            loading ||
            assessmentLoading ||
            campaignDataLoading ||
            !campaignData ||
            !assessmentData
        ) {
            return undefined;
        }
        campaignData && setCampaignTitle(campaignData.campaign.title);
        assessmentData &&
            setEntityTitle(assessmentData.assessment.campaignEntity.name);
    }, [
        campaignData,
        loading,
        assessmentLoading,
        campaignDataLoading,
        assessmentData
    ]);

    useEffect(() => {
        if (loading) {
            return undefined;
        }
        setEntityScores(data.frameworkByCampaign.definition.scores);
        setIsObjectiveComponentExist(data.frameworkByCampaign.definition.hasObjectiveComponents||false);
    }, [loading]);

    useEffect(() => {
        if (frameworkSettings !== undefined &&
            frameworkSettings.frameworkSettingsByCampaign !== undefined &&
            frameworkSettings.frameworkSettingsByCampaign.length > 0) {
            setCompensatingControlEnabled(isCompensatingControlEnable(frameworkSettings.frameworkSettingsByCampaign));
        }
    }, [frameworkSettings]);

    const [
        updateAssessmentCapabilityWorkingNotes,
        { loading: workingNotesUpdating }
    ] = useMutation(UPDATE_WORKING_NOTES);

    const [updateAssessmentCapabilitiesScope] = useMutation(
        UPDATE_ASSESSMENT_CAPABILITY_SCORE
    );

    const [currentDomainIndex, setCurrentDomainIndex] = useState<number>(0);
    const [currentDomainId, setCurrentDomainId] = useState<string>('');
    const [currentCapabilityId, setCurrentCapabilityId] = useState<string>('');
    const [currentCapabilityIndex, setCurrentCapabilityIndex] = useState<
        number
    >(0);

    const [requestAssessmentCapabilityAmends] = useMutation(
        REQUEST_ASSESSMENT_CAPABILITY_AMENDS
    );
    const [isDialogBoxAmendsOpen, setIsDialogBoxAmendsOpen] = useState<boolean>(
        false
    );
    const [responseStatusData, setResponseStatusData] = useState<ResponseStatus | null>(null);
    const [approveAssessmentCapability] = useMutation(
        APPROVE_ASSESSMENT_CAPABILITY
    );
    const [isDialogBoxApproveOpen, setIsDialogBoxApproveOpen] = useState<
        boolean
    >(false);
    const [ updateNotificationByCapabilityId ] = useMutation(updateNotification);

    const handleClickCapabilityApprove = () => {
        approveAssessmentCapability({
            variables: {
                data: {
                    assessmentId: match.params.assessmentId,
                    domainId: currentIdsData.domainId,
                    capabilityId: currentIdsData.capabilityId
                }
            }
        })
            .then(responseData => {
                const {
                    data: {
                        approveAssessmentCapability: { name, status }
                    }
                } = responseData;
                setResponseStatusData({ name, status });
                setIsDialogBoxApproveOpen(true);
            })
            .catch(err => {
                setIsDialogBoxErrorOpen(true);
                console.log('err', err);
            });


        updateNotificationByCapabilityId({
            variables: {
                managerId: managerIds,
                assessmentId: match.params.assessmentId,
                domainId: currentIdsData.domainId,
                capabilityId: currentIdsData.capabilityId,
            },
        }).catch((error: any) => {
            console.log('Error while updating notification :', error);
        });
    };

    const [loadingStatus, setLoadingStatus] = useState(false);

    const [activeTab, setActiveTab] = useState<number>(1);

    const [isOpenWorkingNotes, setIsOpenWorkingNotes] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [isToastOpen, setIsToastOpen] = useState(false);

    // custom hooks
    const currentCapability = useCapability({
        capabilityData: data.assessmentCapability,
        capabilityLoading: loading,
        responseStatusData
    });

    const assessmentDomainsList = useAssessmentDomainsList(
        assessmentLoading,
        assessmentData
    );

    const capabilityObjectives = useCapabilityObjectives({
        objectives: currentCapability.objectives,
        frameworkData: data.frameworkByCampaign,
        currentDomainIndex,
        currentCapabilityIndex
    });

    const domainsList = useDomainsList(data.frameworkByCampaign);

    const [workingNotesText, setWorkingNotesText] = useState<string>('');
    const [isReasonExist, setIsReasonExist] = useState<boolean>(false);
    const scoredCompleted = capabilityObjectives.every(obj=>obj.components && obj.components.every(comp=>!!comp.score));

    useEffect(() => {
        if (loading || assessmentLoading || campaignDataLoading) {
            return undefined;
        }

        currentCapability.workingNotes
            ? setWorkingNotesText(currentCapability.workingNotes.text)
            : setWorkingNotesText('');
    }, [
        currentDomainIndex,
        currentCapability.workingNotes,
        loading,
        assessmentLoading,
        campaignDataLoading
    ]);
    useEffect(() => {
        if (match.params.domainId && match.params.capabilityId) {
            setCurrentIdsData({
                domainId: match.params.domainId,
                capabilityId: match.params.capabilityId
            });
            setCurrentDomainId(match.params.domainId);
            setCurrentCapabilityId(match.params.capabilityId);
        }
    }, []);

    useEffect(() => {
        if (
            !assessmentLoading &&
            !loading &&
            !campaignDataLoading &&
            assessmentData &&
            assessmentData.assessment &&
            assessmentData.assessment.state &&
            assessmentData.assessment.state.domains.length
        ) {
            const domainIndex = assessmentData.assessment.state.domains.findIndex(
                (item: AssessmentDomain) => {
                    return item.id === currentIdsData.domainId;
                }
            );
            if (domainIndex !== -1) {
                const capabilityIndex = assessmentData.assessment.state.domains[
                    domainIndex
                ].capabilities.findIndex((item: AssessmentCapability) => {
                    return item.id === currentIdsData.capabilityId;
                });
                setCurrentDomainIndex(domainIndex);
                setCurrentCapabilityIndex(capabilityIndex);
            }
        }
    }, [
        assessmentData,
        assessmentLoading,
        currentIdsData,
        campaignDataLoading,
        loading
    ]);

    const progress = useMemo(
        () =>
            countProgress({
                progressLength: currentCapability.objectives.filter(obj=>obj.inScope).length,
                value: currentCapability.objectivesScoredCnt
            }),
        [currentCapability]
    );

    useEffect(() =>{
        if(currentCapability.status==='MODERATED'){
            setIsToastOpen(true);
        }
        else{
            setIsToastOpen(false);
        }
    },[currentCapability.status]);

    const handleDescopeCapability = useCallback(
        (values: FormValues, currentIdsDataForHandle: CurrentIdsData, updateScopeReason: any) => {

            setIsReasonExist(false);
            if (!updateScopeReason) {
                setIsReasonExist(true);
                return;
            }

            updateAssessmentCapabilitiesScope({
                variables: {
                    data: {
                        assessmentId: match.params.assessmentId,
                        domainId: currentIdsDataForHandle.domainId,
                        capabilityIds: [values.capabilityId],
                        inScope: false,
                        updateScopeReason
                    }
                }
            })
                .then(() => {
                    setIsModalOpen(false);
                    if(slug === 'MaturityOverview') {
                        Router.goToMaturityOverview(
                            match.params.campaignId
                        );
                    } else {
                        Router.goToAssessmentsMaturityOverview(
                            match.params.campaignId,
                            match.params.assessmentId
                        );
                    }
                })
                .catch(err => {
                    console.log('err', err);
                });
        },
        [
            match.params.assessmentId,
            match.params.campaignId,
            updateAssessmentCapabilitiesScope
        ]
    );

    const maturityScoreCrumbs = [
        {
            name: 'Evaluate (Campaigns)',
            path: '/main/evaluate/campaigns'
        },
        {
            name: `${client ? client.name : ''} | ${campaignTitle}`,
            path: `/main/evaluate/maturity/${match.params.campaignId}`
        },
        {
            name: `${entityTitle}`,
            path: `/main/evaluate/maturity/${match.params.campaignId}/assessments/${match.params.assessmentId}`
        },
        {
            name: currentCapability ? currentCapability.name : ''
        }
    ];

    const handleClickOpenWorkingNotesModal = () => {
        setIsOpenWorkingNotes(true);
    };

    const handleSaveWorkingNotes = (text: string) => {
        updateAssessmentCapabilityWorkingNotes({
            variables: {
                data: {
                    assessmentId: match.params.assessmentId,
                    domainId: currentIdsData.domainId,
                    capabilityId: currentIdsData.capabilityId,
                    workingNotes: {
                        text
                    }
                }
            }
        })
            .then(() => {
                setIsOpenWorkingNotes(false);
                setWorkingNotesText(text);
            })
            .catch(() => (
                <Notification show content="Error Save Working Notes!" />
            ));
    };

    const handleClickCapabilityAmends = () => {
        requestAssessmentCapabilityAmends({
            variables: {
                data: {
                    assessmentId: match.params.assessmentId,
                    domainId: currentIdsData.domainId,
                    capabilityId: currentIdsData.capabilityId
                }
            }
        })
            .then(responseData => {
                const {
                    data: {
                        requestAssessmentCapabilityAmends: { name, status }
                    }
                } = responseData;
                setResponseStatusData({ name, status });
                setIsDialogBoxAmendsOpen(true);
            })
            .catch(err => {
                setIsDialogBoxErrorOpen(true);
                console.log('err', err);
            });
    };

    const userMayApprove =
        (user && user.id && campaignData &&
            campaignData.campaign &&
            campaignData.campaign.manager &&
            campaignData.campaign.manager.some(manager => manager.id === user.id));
            const managers = campaignData &&campaignData.campaign && campaignData.campaign.manager.map(id => id);

    const currentDomainData = {
        currentDomainIndex,
        setCurrentDomainIndex,
        domainId: currentDomainId,
        setCurrentDomainId
    };

    const currentCapabilityData = {
        currentCapability,
        currentCapabilityIndex,
        setCurrentCapabilityIndex,
        setCurrentCapabilityId
    };

    const [markdownValue, setMarkdownValue] = useState<string>('');


    let campaign_closed_message = 'This campaign is closed - view only access.';
    const isReadOnly = (campaignData && campaignData.campaign.readOnly || assessmentData?.assessment.permission == PermissionTypeEnum.READ_ONLY_ACCESS || false);
    const readOnlyEnabled = isReadOnlyEnabled(globalSettings);

    // tslint:disable-next-line: no-redundant-boolean
    const pointOneDecimalEnable = !isPointOneDecimalEnable(globalSettings) ? false : campaignData && campaignData.campaign.pointOneDecimalEnable || false;

    const frameworkName = data && data.frameworkByCampaign.definition.name;
    const maturityTabsList = [
        {
            id: 1,
            label: 'Objectives',
            iconActive: vectorIcon,
            iconDisable: vectorIconWhite
        },
        {
            id: 2,
            label: 'Moderation',
            iconActive: noteAdd,
            iconDisable: noteAddWhite
        }
    ];


    (!(readOnlyEnabled && isReadOnly) || !readOnlyEnabled) && maturityTabsList.push({
        id: 3,
        label: 'Meeting notes',
        iconActive: eventNoteIcon,
        iconDisable: eventNoteIconWhite
    });



    /**
     * We switch the flag on based on if the user has the right role, if closed we keep that way... if on the other hand not, then we flag closed but with the
     * message switched.
     */

    /**
     * removed the campaignIsClosed filter as passed down from parent already set
     */
    if (readOnlyEnabled /*&& !campaignIsClosed*/ && isReadOnly) {
        // BRUTE HACK TURN ON CAMPAIGN IS CLOSED MODE
        campaignIsClosed = true;
        campaign_closed_message = 'You have read-only access to this assessment.';
    }
 const navigateTab=(id: number)=>{
        if(!isDirty){
            setActiveTab(id);
        }
        else{
            setConfirmedTabId(id);
            setIsChangeTabPopUpOpen(true);
            setDirty(false);
        }
 };
    const confirmNavigation=()=>{
        setActiveTab(confirmedTabId);
        setIsChangeTabPopUpOpen(false);
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const frameworkCapabilities = assessmentData?.assessment?.framework.definition.domains.reduce(
        (newArray: any, domain: { capabilities: any; shortName: string }): any => {
            const updatedObject = domain.capabilities.map((c: any) => ({
                ...c,
                ['domainShortName']: domain.shortName

            }));
            return [...newArray, ...updatedObject];
        },
        []
    );

    return (
        <ScoreContainer>
            {(loading || assessmentLoading || campaignDataLoading) && (
                <SpinnerWithOverlay />
            )}
            <HeaderContainer>
                {/* <BackBtnContainer
                    onClick={
                        lastLocation && lastLocation.pathname
                            ? handleRedirectPreviousPage
                            : () =>
                                  Router.goToMaturityOverview(
                                      match.params.campaignId
                                  )
                    }
                >
                    <ImgContainer src={leftArrow} alt="left arrow" />
                    <BoldText color={'#4f4f4f'}>Back</BoldText>
                </BackBtnContainer> */}

                <CapabilityMenu
                    currentDomainData={currentDomainData}
                    currentCapabilityData={currentCapabilityData}
                    currentIdsData={currentIdsData}
                    assessmentDomainsListOriginal={assessmentDomainsList}
                    setCurrentIdsData={setCurrentIdsData}
                    assessmentId={match.params.assessmentId}
                    setResponseStatusData={setResponseStatusData}
                    readOnly={(readOnlyEnabled && isReadOnly)}
                    level="domains-and-capabilities"
                    isDirty={isDirty}
                    setDirty={setDirty}
                    assessmentReadOnly={assessmentData?.assessment.permission == PermissionTypeEnum.READ_ONLY_ACCESS || campaignData?.campaign.readOnly}
                />

                <AlignItems>
                    {/*<ImgContainer src={iconAid} alt="icon alt" />*/}
                    {/*<BoldText*/}
                    {/*    color="#de3328"*/}
                    {/*    style={{ textDecoration: 'underline' }}*/}
                    {/*>*/}
                    {/*    Framework Aid*/}
                    {/*</BoldText>*/}
                </AlignItems>
            </HeaderContainer>
            {campaignIsClosed && <NotificationCloseCampaign message={ campaign_closed_message } />}
            <SpaceBetween>
                <Breadcrumb crumbs={maturityScoreCrumbs} />
                <AlignItems>
                    <img
                        src={iconAssCovered}
                        alt="campaign progress icon"
                        style={{ marginRight: 8 }}
                    />

                    <Column style={{ width: '100%' }}>
                        <Flex style={{ marginBottom: 4 }}>
                            <Text>
                                {currentCapability.objectivesScoredCnt}/
                                {currentCapability.objectives.filter(obj=>obj.inScope).length} &nbsp;
                            </Text>
                            <Text>{`Capability status - ${  currentCapability.status && 
                            currentCapability.objectivesScoredCnt > 0 && (currentCapability.status
                                .toLowerCase() === 'not_started' ) ? 'scored' :
                                currentCapability.status &&
                                currentCapability.status
                                    .toLowerCase()
                                    .split('_')
                                    .join(' ')}`}</Text>
                        </Flex>
                        <LinearProgress progress={progress} />
                    </Column>
                </AlignItems>
            </SpaceBetween>

            <SubheaderContainer>
                <AlignItems>
                    <H1Text color={'#4f4f4f'}>
                        Maturity score |{' '}
                        {assessmentDomainsList[currentDomainIndex] &&
                            assessmentDomainsList[currentDomainIndex].name}{' '}
                        - {currentCapability.name}
                    </H1Text>
                </AlignItems>
                <Flex>
                    <AlignItems style={{ marginLeft: '15px' }}>
                        {userMayApprove &&
                            (currentCapability.status === 'REVIEW_REQUESTED' ||
                                (currentCapability.status === 'MODERATED' || (isObjectiveComponentExist && currentCapability.status === 'SCORED'))) && (
                                <RedButtonWithMarginRight
                                    variant="outlined"
                                    disabled={!canEdit}
                                    onClick={handleClickCapabilityApprove}
                                >
                                    <ImgContainer
                                        src={approveIcon}
                                        alt="Approve "
                                    />
                                    Approve
                                </RedButtonWithMarginRight>
                            )}

                        {userMayApprove &&
                            (currentCapability.status === 'REVIEW_REQUESTED' ||
                                (currentCapability.status === 'MODERATED' ||(isObjectiveComponentExist && currentCapability.status === 'SCORED'))) && (
                                <RedButtonWithMarginRight
                                    variant="outlined"
                                    disabled={!canEdit}
                                    onClick={handleClickCapabilityAmends}
                                >
                                    <ImgContainer
                                        src={requestAmendsIcon}
                                        alt="Request Amendments"
                                    />
                                    Request Amendments
                                </RedButtonWithMarginRight>
                            )}
                        {((currentCapability.status === 'MODERATED' ||(isObjectiveComponentExist && currentCapability.status === 'SCORED')) ||
                            currentCapability.status ===
                                'AMENDS_REQUESTED') && (
                            <RedButtonWithMarginRight
                                variant="outlined"
                                disabled={!canEdit}
                                onClick={
                                    handleRequestAssessmentCapabilityReview
                                }
                            >
                                <ImgContainer
                                    src={sendIcon}
                                    alt="submit-review"
                                />
                                Submit for review
                            </RedButtonWithMarginRight>
                        )}

                        {currentCapability.inScope ? (
                            <ButtonWithMarginRight
                                disabled={!canEdit || assessmentData?.assessment.permission === PermissionTypeEnum.READ_ONLY_ACCESS }
                                variant="outlined"
                                aria-label='descope button'
                                onClick={() => setIsModalOpen(true)}
                            >
                                <DescopeIcon />
                                Descope
                            </ButtonWithMarginRight>
                        ) : null}

                        {(!(readOnlyEnabled && isReadOnly) || (readOnlyEnabled && !isReadOnly)) &&
                        <Button
                            style={{ minHeight: '58px' }}
                            onClick={handleClickOpenWorkingNotesModal}
                            variant="outlined"
                        >
                            <ImgContainer src={iconPen} alt="archive icon" />
                            <Text>Working notes</Text>
                        </Button>
                    }
                    </AlignItems>
                </Flex>
            </SubheaderContainer>
            <ContentContainer>
                <SpaceBetween>
                    <AlignItems>
                        {maturityTabsList.map(
                            (
                                item: MaturityTabsList,
                                index: number,
                                array: MaturityTabsList[]
                            ) => {
                                return (
                                    <Tab
                                        active={activeTab === item.id}
                                        handleClick={() =>navigateTab(item.id)
                                        }
                                        key={item.id}
                                        index={index}
                                        lastTab={
                                            maturityTabsList.length - 1 ===
                                            index
                                        }
                                    >
                                        <TabContentContainer
                                            isActive={activeTab === item.id}
                                        >
                                            <ImgContainer
                                                src={
                                                    activeTab === item.id
                                                        ? item.iconActive
                                                        : item.iconDisable
                                                }
                                                alt={'icon'}
                                            />
                                            <Text
                                                style={{
                                                    color: `${
                                                        activeTab === item.id
                                                            ? 'black'
                                                            : 'white'
                                                    }`
                                                }}
                                            >
                                                {item.label}
                                            </Text>
                                        </TabContentContainer>
                                        {activeTab !== index + 1 &&
                                            index !== array.length - 1 &&
                                            index !== activeTab - 2 && (
                                                <VerticalLine />
                                            )}
                                    </Tab>
                                );
                            }
                        )}
                    </AlignItems>
                    <AlignItems>
                        <ArrowTooltip
                            title={
                                loadingStatus
                                    ? 'Loading...'
                                    : 'Every change that you make is automatically saved in Connected Risk Engine.'
                            }
                            background={'#2f2f2f'}
                        >
                            <Link>{savingStatus}</Link>
                        </ArrowTooltip>
                    </AlignItems>
                </SpaceBetween>
                <MainContentContainer>
                    {renderContent({
                        frameworkCapabilities,
                        setLoadingStatus,
                        setSavingStatus,
                        activeTab,
                        currentDomainIndex,
                        currentCapabilityIndex,
                        entityScores,
                        currentAssessmentId: match.params.assessmentId,
                        currentCapability,
                        setActiveTab,
                        currentCapabilityId,
                        currentDomainId,
                        currentIdsData,
                        capabilityObjectives,
                        campaignData,
                        canEdit,
                        match,
                        isModalOpen,
                        readOnlyEnabled,
                        isReadOnly,
                        setDirty,
                        isDirty,
                        pointOneDecimalEnable,
                        setResponseStatusData,
                        isObjectiveComponentExist: isObjectiveComponentExist,
                        frameworkName,
                        compensatingControlEnabled,
                        assessmentData
                    })}
                </MainContentContainer>
                <Toast
                    open={isToastOpen}
                    title={capabilityModeratedMessage}
                    closeToast={()=>setIsToastOpen(false)}
                    icon={'check'}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                />
            </ContentContainer>

            <WorkingNotesModal
                workingNotes={workingNotesText}
                saveWorkingNotes={handleSaveWorkingNotes}
                isOpenWorkingNotes={isOpenWorkingNotes}
                setIsOpenWorkingNotes={setIsOpenWorkingNotes}
                workingNotesUpdating={workingNotesUpdating}
                canEdit={canEdit}
            />

            <Modal
                isOpen={isModalOpen}
                shouldCloseOnOverlayClick
                ariaHideApp={false}
                style={customModalStyles}
                onRequestClose={() => setIsModalOpen(false)}
            >
                <>
                    <DescopePopup
                        handleClose={() => setIsModalOpen(false)}
                        title="Descope Capability?"
                        mainText={`In order to descope the "${domainsList[
                            currentDomainIndex
                        ] &&
                            domainsList[currentDomainIndex].name +
                                '-' +
                                currentCapability.name}" capability, please provide a reason`}
                        descriptionText={
                            'Note: Score(s) will be eliminated and reset. Descoped capabilities will be visible on the campaign/assesment maturity overview pages. They can be reactivated from there as well.'
                        }
                        initialValues={{
                            reason: '',
                            capabilityId: currentIdsData.capabilityId
                        }}
                        render={(formData: FormData) => {
                            return (
                                <>
                                    <ReactQuillWrap
                                        value={markdownValue}
                                        onChange={setMarkdownValue}
                                    />
                                    <ButtonContainer>
                                        {isReasonExist &&
                                            <Text color="red">
                                                Reason is required
                                            </Text>
                                        }
                                        <SaveButton
                                            key="SaveButton"
                                            onClick={() => {
                                                handleDescopeCapability(
                                                    formData.values,
                                                    currentIdsData,
                                                    markdownValue
                                                );
                                            }}>Descope
                                        </SaveButton>
                                    </ButtonContainer>
                                </>
                            );
                        }}
                    />
                </>
            </Modal>

            <DialogBox
                title={'Amendments requested!'}
                buttonSaveText="Done"
                content={`The ${currentCapability.name} capability has been submitted for amendments.`}
                open={isDialogBoxAmendsOpen}
                handleClose={() => setIsDialogBoxAmendsOpen(false)}
                handleSubmit={() => setIsDialogBoxAmendsOpen(false)}
            />

            <DialogBox
                title={'Approved'}
                buttonSaveText="Done"
                content={ isNISTFramework(frameworkName) ? `The ${currentCapability.name} capability has been approved.`
                 : `The ${currentCapability.name} capability has been approved.
                You can reverse this by resetting the Moderated score in the Moderation tab.`}
                open={isDialogBoxApproveOpen}
                handleClose={() => setIsDialogBoxApproveOpen(false)}
                handleSubmit={() => setIsDialogBoxApproveOpen(false)}
            />

            <DialogBox
                title={'Submitted for review'}
                buttonSaveText="Done"
                content={`The ${currentCapability.name} capability has been submitted for review.`}
                open={isDialogBoxSubmitOpen}
                handleClose={() => setIsDialogBoxSubmitOpen(false)}
                handleSubmit={() => setIsDialogBoxSubmitOpen(false)}
            />

            <DialogBox
                title={'Error'}
                buttonSaveText="Done"
                content={`${currentCapability.name} request errors.`}
                open={isDialogBoxErrorOpen}
                handleClose={() => setIsDialogBoxErrorOpen(false)}
                handleSubmit={() => setIsDialogBoxErrorOpen(false)}
            />
            <DialogBox
                title={''}
                content={autoSaveMessage}
                open={isChangeTabPopUpOpen}
                handleClose={()=>{
                    setIsChangeTabPopUpOpen(false);
                    setDirty(true);
                }}
                handleSubmit={()=>confirmNavigation()}
                buttonCancelText={'No'}
                buttonSaveText={'Yes'}
            />
            <Buffer/>
            <FooterContainer>

                <CapabilityMenu
                    currentDomainData={currentDomainData}
                    currentCapabilityData={currentCapabilityData}
                    currentIdsData={currentIdsData}
                    assessmentDomainsListOriginal={assessmentDomainsList}
                    setCurrentIdsData={setCurrentIdsData}
                    assessmentId={match.params.assessmentId}
                    setResponseStatusData={setResponseStatusData}
                    readOnly={(readOnlyEnabled && isReadOnly)}
                    level="domains-and-capabilities"
                    isDirty={isDirty}
                    setDirty={setDirty}
                    assessmentReadOnly={assessmentData?.assessment.permission == PermissionTypeEnum.READ_ONLY_ACCESS || campaignData?.campaign.readOnly}
                />

                <AlignItems/>
            </FooterContainer>

        </ScoreContainer>
    );
};

const ButtonContainer = styled(Flex)`
    flex-direction: column;

    span {
        margin-bottom: 5px;
    }
`;

const ButtonWithMarginRight = styled(Button)`
    && {
        min-height: 58px;
        margin-right: 10px;
    }
`;

const RedButtonWithMarginRight = styled(RedButton)`
    && {
        min-height: 58px;
        margin-right: 10px;
    }
`;

const ContentContainer = styled(ContentWrapper)`
    width: 100%;
    min-width: 1000px;
    padding: 10px 0;
`;

const ScoreContainer = styled(Column)`
    /*padding-bottom: 100px;*/
    justify-content:flex-start;
    min-height:100vh;
`;

const Buffer = styled(Column)`
    padding-bottom: 100px;
`;

const MainContentContainer = styled('div')`
    padding: 20px;
    border: solid 1px #d9d9d9;
    background: white;
`;

const TabContentContainer = styled('div')<{ isActive: boolean }>`
    padding: 5px;
    display: flex;
    align-items: center;
    height: 47px;
`;

const SubheaderContainer = styled(SpaceBetween)`
    width: 100%;
    align-items: center;
    padding: 10px 0;
`;

const HeaderContainer = styled(JustifyContentCenter)`
    background-color: #fff;
    margin: 0 -5.5% 24px -8.8%;
    padding-left: 8.8%;
    padding-right: 3.9%;
    padding-top: 8px;
    padding-bottom: 8px;
`;

const FooterContainer = styled(JustifyContentCenter)`
    background-color: #fff;
    margin: auto -5.5% 0 -8.8%;
    padding-left: 8.8%;
    padding-right: 3.9%;
    padding-top: 8px;
    padding-bottom: 8px;
    min-height:60px;
`;

const SaveButton = styled(RedButton)`
    && {
        padding: 0 56px;
    }
`;

const MarkDownContainer = styled('div')`
    padding-top: 20px;
    .public-DraftEditor-content {
        min-height: 164px;
    }
`;
const ReactQuillWrap = styled((props: ReactQuillProps) =>
    <ReactQuill
        theme="snow"
        {...props}
    />
)`
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    width: 100%;
    box-sizing: border-box;

    && {
        font-family: inherit;
        padding-top: 0px;
        height:250px;
        margin-top:10px;
        margin-bottom:10px;
    }

    .ql-container.ql-snow {
        border: 1px solid #ccc;
        border-top: 1px solid #ccc !important;
    }

    .ql-editor {
        height: 100%;
        padding: 16px;
        font-size: 14px;
        letter-spacing: 0.5px;
        line-height: 1em;
        color: #2d2d2d;
        overflow-y: hidden;
        min-height: 160px;
    }

    .ql-toolbar {
        flex-wrap: wrap;
        box-shadow: 0px -1px 9px 0px #cdcdcd;
        margin: 0;
        border: 0;
        padding: 16px 24px;
        display: flex;
        justify-content: space-between;
    }
`;

export default MaturityScore;
